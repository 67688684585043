import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import routes from "../../constants/routes";
import Logo from "../../assets/logo_long.png";
import styles from "./Header.module.scss";

const Header = (props) => {
  const menuLinks = [
    routes.home,
    routes.us,
    routes.services,
    routes.ourWorks,
    routes.products,
    routes.contact,
  ];

  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <header className={`${styles.header} ${scrolled ? styles.scrolled : ""}`}>
      <div className={`wrapper ${styles.wrap}`}>
        <Link to={routes.home.path}>
          <img src={Logo} alt="Moto Asesor" className={styles.logo} />
        </Link>

        <nav className={`${styles.nav} ${menuOpen ? styles.active : ""}`}>
          <ul>
            {menuLinks.map((link) => (
              <li key={link.label}>
                <NavLink
                  className={({ isActive }) => (isActive ? styles.active : "")}
                  to={link.path}
                  onClick={() => setMenuOpen(false)}
                >
                  {link.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>

        <button
          className={`${styles.pancake} ${menuOpen ? styles.active : ""}`}
          onClick={() => setMenuOpen((prev) => !prev)}
        >
          <div className={styles.pancakeButton}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <span className={styles.pancakeLabel}>Menú</span>
        </button>
      </div>
    </header>
  );
};

export default Header;
