import React, { useRef, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { TbError404 } from "react-icons/tb";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import SectionGradient from "../common/SectionGradient/SectionGradient";
import styles from "./Bike.module.scss";
import Slider from "react-slick";
import BikeSkeleton from "./BikeSkeleton";
import { FaCalendar, FaGaugeHigh, FaWhatsapp } from "react-icons/fa6";
import { capitalizeFirstLetter, formatByThousands } from "../../utils";
import QUERIES from "../../constants/queries";
import routes from "../../constants/routes";
import DOMPurify from "dompurify";

const Bike = () => {
  const [product, setProduct] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const { motoId: id } = useParams();

  const fetchProduct = async () => {
    const response = await fetch(
      `https://webapplication120240725104935.azurewebsites.net/api/Motorcycle/Get?id=${id}`
    );

    if (!response.ok) {
      const error = new Error("An error occurred while fetching the data.");
      error.status = response.status;
      throw error;
    }

    return response.json();
  };

  const { isLoading, error } = useQuery(
    [QUERIES.FETCH_PRODUCT, id],
    fetchProduct,
    {
      onSuccess: (data) => {
        setProduct(data);
      },
      onError: (error) => {
        if (error.status === 404) {
          setNotFound(true);
        }
      },
      enabled: typeof id === "string" && !!id,
      retry: (failureCount, error) => {
        // Don't retry on 404 errors
        if (error.status === 404) return false;
        // Retry once for other errors
        return failureCount < 1;
      },
    }
  );

  const settings = {
    beforeChange: (_, newIndex) => setActiveSlide(newIndex),
    infinite: false,
  };

  const handleThumbnailClick = (index) => {
    if (sliderRef.current) sliderRef.current.slickGoTo(index);
  };

  const sliderRef = useRef(null);

  return (
    <div className={styles.bike}>
      <SectionGradient />
      <div className={`wrapper ${styles.bike_content}`}>
        <Link className={`page_title`} to={routes.products.path}>
          <BsArrowLeft className={`page_title_icon`} />
          Volver a productos
        </Link>

        {notFound ? (
          <div className={styles.notFound}>
            <TbError404 />
            <p>La moto que estás buscando no se encuentra disponible.</p>
          </div>
        ) : isLoading ? (
          <BikeSkeleton />
        ) : (
          product && (
            <div className={styles.product}>
              <div className={`${styles.slider} bike_slider`}>
                <Slider {...settings} ref={sliderRef}>
                  {product?.urlFiles?.map((file) => (
                    <div className={styles.slide} key={file}>
                      <img className={styles.slide_img} src={file} alt="" />
                    </div>
                  ))}
                </Slider>

                <div className={styles.thumbnails}>
                  {product?.urlFiles?.map((file, index) => (
                    <button
                      onClick={() => handleThumbnailClick(index)}
                      className={`${styles.thumbnail} ${
                        index === activeSlide ? styles.active : ""
                      }`}
                      key={file}
                    >
                      <img src={file} alt="" />
                    </button>
                  ))}
                </div>
              </div>

              <div className={styles.data}>
                <h1 className={styles.title}>{product.name}</h1>
                {product.category && (
                  <h3 className={styles.category}>
                    {capitalizeFirstLetter(product.category)}
                  </h3>
                )}

                {!product.isArticle && (
                  <ul className={styles.metadata}>
                    <li>
                      <FaGaugeHigh />
                      <span className={styles.metadata_label}>
                        Kilómetros:
                      </span>{" "}
                      <b>{formatByThousands(product.kilometres)} km</b>
                    </li>
                    <li>
                      <FaCalendar />
                      <span className={styles.metadata_label}>Año:</span>{" "}
                      <b>{product.year}</b>
                    </li>
                  </ul>
                )}

                <p className={styles.price}>
                  {product.isDollarPrice ? "U$S" : "$"}{" "}
                  {formatByThousands(product.price)}
                </p>

                <div className={styles.ctas}>
                  <button className={`button bold `}>Consultar</button>
                  <a
                    className={`button bold secondary ${styles.whatsApp}`}
                    href={`https://api.whatsapp.com/send?phone=541156372706&text=Hola, quiero consultar por el producto: ${product.name}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaWhatsapp />
                    WhatsApp
                  </a>
                </div>

                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(product.description),
                  }}
                ></div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Bike;
