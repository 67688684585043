import { Navigate } from "react-router-dom";
import useStore from "../store/store";
import routes from "../constants/routes";

const PrivateRoute = ({ outlet: Outlet }) => {
  const user = useStore((state) => state.user);

  if (!user) return <Navigate to={routes.adminLogin.path} />;
  return <Outlet />;
};

export default PrivateRoute;
