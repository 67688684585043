import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const Pics = ({ photos, removePhoto, existent = false }) => {
  if (photos.length === 0) return null;

  return (
    <Box mt={2} style={{ marginBottom: "24px" }}>
      <Typography variant="h6" gutterBottom>
        {existent ? "Fotos actuales" : "Fotos nuevas seleccionadas"} (
        {photos.length})
      </Typography>
      <Grid container spacing={2}>
        {photos.map((photo, index) => (
          <Grid item xs={4} sm={3} md={2} key={existent ? photo : photo.name}>
            <Box
              position="relative"
              sx={{
                "&:hover .remove-btn": { display: "block" },
                paddingTop: "100%", // This creates a 1:1 aspect ratio
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <img
                  src={existent ? photo : photo.preview}
                  alt={`preview ${index}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    display: "block",
                  }}
                />
                <Button
                  className="remove-btn"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => {
                    if (existent) {
                      removePhoto.mutate({ url: photo });
                    } else {
                      removePhoto(index);
                    }
                  }}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    display: "none",
                  }}
                >
                  X
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Pics;
