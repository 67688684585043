import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import HomeBg from "../../../assets/home_bg.jpg";
import QUERIES from "../../../constants/queries";
import routes from "../../../constants/routes";
import { shuffleArray } from "../../../utils";
import ProductCard from "../../common/ProductCard/ProductCard";
import ProductCardSkeleton from "../../common/ProductCard/ProductCardSkeleton";
import styles from "./HomeProducts.module.scss";

const HomeProducts = (props) => {
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    const response = await fetch(
      "https://webapplication120240725104935.azurewebsites.net/api/Motorcycle/GetAll"
    );

    if (!response.ok) {
      throw new Error("Error fetching");
    }
    return response.json();
  };

  const { isLoading, error } = useQuery(
    QUERIES.FETCH_PRODUCTS_HOME,
    fetchProducts,
    {
      onSuccess: (data) => {
        setProducts(
          shuffleArray(data.filter((item) => !item.isArticle)).slice(0, 4)
        );
      },
    }
  );

  return (
    <div
      style={{ backgroundImage: `url(${HomeBg})` }}
      className={`section ${styles.section}`}
    >
      <div className='wrapper'>
        <h2 className={`section_subtitle light`}>Motos en venta</h2>

        <div className={styles.products}>
          {isLoading ? (
            <>
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <ProductCardSkeleton />
            </>
          ) : (
            products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))
          )}
        </div>

        <Link
          to={routes.products.path}
          className={`button_link ${styles.see_more}`}
        >
          Ver más motos
        </Link>
      </div>
    </div>
  );
};

export default HomeProducts;
