const routes = {
  home: {
    path: "/",
    label: "Home",
  },
  us: {
    path: "/nosotros",
    label: "Nosotros",
  },
  services: {
    path: "/servicios",
    label: "Servicios",
  },
  ourWorks: {
    path: "/nuestros-trabajos",
    label: "Trabajos",
  },
  products: {
    path: "/tienda",
    label: "Productos",
    dynamicPath: "/tienda/:motoId",
  },
  contact: {
    path: "/contactanos",
    label: "Contactanos",
  },
  adminBase: {
    path: "/admin",
  },
  adminProducts: {
    list: {
      path: "/admin/productos",
      label: "Admin | Productos",
    },
    new: {
      path: "/admin/productos/nuevo",
      label: "Admin | Cargar producto",
    },
  },
  adminJobs: {
    list: {
      path: "/admin/trabajos",
      label: "Admin | Trabajos",
    },
    new: {
      path: "/admin/trabajos/nuevo",
      label: "Admin | Cargar trabajo",
    },
  },
  adminUsers: {
    path: "/admin/usuarios",
    label: "Admin | Usuarios",
  },
  adminLogin: {
    path: "/admin/login",
    label: "Admin | Login",
  },
};

export default routes;
