import React from "react";
import styles from "./Us.module.scss";
import SectionGradient from "../common/SectionGradient/SectionGradient";
import UsImg from "../../assets/us_2.jpg";

const Us = () => {
  return (
    <div className={styles.us}>
      <SectionGradient />

      <div className={`wrapper ${styles.us_content}`}>
        <h1 className="page_title">Nosotros</h1>

        <div className={styles.us_blocks}>
          <div className={`${styles.us_block} ${styles.block_text}`}>
            <p>
              Realizamos diagnóstico avanzado, reparación y mantenimiento de
              motos. Utilizando los mejores equipos y herramientas. Y entregando
              evidencia de todo el trabajo realizado mediante imágenes, videos e
              informes. Rápida resolución y con garantía.
            </p>

            <p>
              Ofrecemos motos en venta, las cuales ya hayan pasado por el taller
              y por lo tanto podamos garantizar su correcto estado. O también
              motos que a la apariencia se encuentren impecables y yendo en
              persona a revisarlas para poder confirmar su correcto
              funcionamiento.
            </p>

            <p>
              Tenemos un grupo de WhatsApp exclusivo, donde se muestran los
              problemas del día a día y su resolución, sin guardarnos ningún
              detalle. Además de proveer información general para resolución de
              problemas de todo tipo. ¡Unite!
            </p>

            <p>Moto Asesor es confianza, garantía y eficiencia.</p>
          </div>
          <div className={styles.us_block}>
            <img src={UsImg} className={styles.us_img} alt="Nosotros" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Us;
