import DOMPurify from "dompurify";
import React, { useRef, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useQuery } from "react-query";
import Slider from "react-slick";
import QUERIES from "../../constants/queries";
import SectionGradient from "../common/SectionGradient/SectionGradient";
import styles from "./Jobs.module.scss";

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState();
  const [activeSlide, setActiveSlide] = useState(0);

  const fetchJobs = async () => {
    const response = await fetch(
      "https://webapplication120240725104935.azurewebsites.net/api/Job/GetAll"
    );
    if (!response.ok) {
      throw new Error("Error fetching");
    }
    return response.json();
  };

  const { isLoading, error } = useQuery(QUERIES.FETCH_JOBS, fetchJobs, {
    onSuccess: (data) => {
      setJobs(data);
    },
  });

  const settings = {
    beforeChange: (_, newIndex) => {
      setActiveSlide(newIndex);

      const videos = document.querySelectorAll(".job_video_container video");
      videos.forEach((video) => {
        video.pause();
        video.currentTime = 0;
      });
    },
    infinite: false,
  };

  const handleThumbnailClick = (index) => {
    if (sliderRef.current) sliderRef.current.slickGoTo(index);
  };

  const sliderRef = useRef(null);

  const sliderItems = !selectedJob
    ? []
    : [
        ...selectedJob?.urlPhotos.map((photo) => ({
          type: "PHOTO",
          url: photo,
        })),
        ...selectedJob?.urlVideos.map((video) => ({
          type: "VIDEO",
          url: video,
        })),
      ];

  console.log({ sliderItems });

  return (
    <div className={styles.jobs}>
      <SectionGradient />

      <div className={`wrapper ${styles.jobs_content}`}>
        <h1 className='page_title'>Nuestros trabajos</h1>

        {selectedJob ? (
          <>
            <button
              className={`link ${styles.go_back}`}
              onClick={() => setSelectedJob(null)}
            >
              <BiArrowBack /> Volver a la lista
            </button>
            <div className={styles.job}>
              <div className={styles.job_slider}>
                <Slider {...settings} ref={sliderRef}>
                  {sliderItems.map((item) => (
                    <>
                      {item.type === "PHOTO" ? (
                        <div className={styles.slide} key={item.url}>
                          <img
                            className={styles.slide_img}
                            src={item.url}
                            alt=''
                          />
                        </div>
                      ) : (
                        <div
                          className={`${styles.slide} ${styles.slide_vid_container} job_video_container`}
                          key={item.url}
                        >
                          <video controls muted className={styles.slide_vid}>
                            <source src={item.url} type='video/mp4'></source>
                          </video>
                        </div>
                      )}
                    </>
                  ))}
                </Slider>

                <div className={styles.thumbnails}>
                  {sliderItems.map((item, index) => (
                    <>
                      {item.type === "PHOTO" ? (
                        <button
                          onClick={() => handleThumbnailClick(index)}
                          className={`${styles.thumbnail} ${
                            index === activeSlide ? styles.active : ""
                          }`}
                          key={item.url}
                        >
                          <img src={item.url} alt='' />
                        </button>
                      ) : (
                        <button
                          onClick={() => handleThumbnailClick(index)}
                          className={`${styles.thumbnail} ${
                            index === activeSlide ? styles.active : ""
                          }`}
                          key={item.url}
                        >
                          <video muted className={styles.thumbnail_vid}>
                            <source src={item.url} type='video/mp4'></source>
                          </video>
                        </button>
                      )}
                    </>
                  ))}
                </div>
              </div>

              <div className={styles.job_data}>
                <h3>{selectedJob.title}</h3>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(selectedJob.description),
                  }}
                ></div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.grid}>
            {isLoading ? (
              [...Array(8)].map((e, i) => (
                <div className={`skeleton dark ${styles.skeleton}`}></div>
              ))
            ) : (
              <>
                {jobs.map((job, index) => (
                  <button
                    onClick={() => setSelectedJob(job)}
                    key={`${job.id}.${job.index}`}
                    className={styles.item}
                  >
                    <img
                      src={job.urlPhotos?.[0]}
                      alt={job.title}
                      className={styles.item_img}
                    />

                    <span className={styles.item_name}>{job.title}</span>
                  </button>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Jobs;
