import React from "react";
import { Link } from "react-router-dom";
import routes from "../../../constants/routes";
import { formatByThousands } from "../../../utils";
import styles from "./ProductCard.module.scss";

const ProductCard = ({ product }) => {
  return (
    <Link to={`${routes.products.path}/${product.id}`} className={styles.card}>
      <img
        className={styles.img}
        src={product.urlFiles?.[0]}
        alt={product.name}
        height={400}
      />
      <div className={styles.data}>
        <p className={styles.title}>{product.name}</p>

        {!product.isArticle && (
          <p className={styles.metadata}>
            {product.year} <span className={styles.separator}>|</span>{" "}
            {product.kilometres === 0
              ? "0 km"
              : `${formatByThousands(product.kilometres)} km`}
          </p>
        )}

        <p className={styles.price}>
          {product.isDollarPrice ? "U$S" : "$"}{" "}
          {formatByThousands(product.price)}
        </p>
      </div>
    </Link>
  );
};

export default ProductCard;
