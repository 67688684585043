import React from "react";
import styles from "./HomeUs.module.scss";

const HomeUs = (props) => {
  return (
    <div className={`section ${styles.section}`}>
      <div className="wrapper">
        <h2 className="section_subtitle">¿Quiénes somos?</h2>
        <p className="section_text">
          Somos un grupo de técnicos capacitados, que trabajan con pasión y
          honestidad. También nos gusta disfrutar de andar en moto y por lo
          tanto entendemos la importancia de su correcto funcionamiento cada vez
          que salimos.
        </p>
        <p className="section_text">
          Equipo dirigido por un Ingeniero mecánico recibido de la UTN FRBA.
        </p>
      </div>
    </div>
  );
};

export default HomeUs;
