import React, { useState } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2";
import styles from "./Login.module.scss";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import Logo from "../../../assets/logo_long.png";
import useStore from "../../../store/store";

const Login = () => {
  const setUser = useStore((state) => state.setUser);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        "https://webapplication120240725104935.azurewebsites.net/api/Auth/login",
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: email,
            password,
          }),
        }
      );

      if (response.ok) {
        setLoggedIn(true);
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error al iniciar sesión",
        text: "¡Usuario o contraseña incorrecta!",
      });
    }
  };

  return (
    <div className={styles.login}>
      <form onSubmit={handleLogin} className={styles.form}>
        <img src={Logo} alt="Moto Asesor Login" className={styles.logo} />

        <TextField
          label="Email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          label="Contraseña"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading || loggedIn}
          className={styles.loginButton}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : loggedIn ? (
            "Entrando..."
          ) : (
            "Iniciar sesión"
          )}
        </Button>
      </form>
    </div>
  );
};

export default Login;
