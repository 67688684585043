import React from "react";
import styles from "./Services.module.scss";
import SectionGradient from "../common/SectionGradient/SectionGradient";
import { GiAutoRepair } from "react-icons/gi";
import { TbZoomQuestion } from "react-icons/tb";
import { BsTools } from "react-icons/bs";
import { MdOutlineSell } from "react-icons/md";

const Services = () => {
  return (
    <div className={styles.services}>
      <SectionGradient />

      <div className={`wrapper ${styles.services_content}`}>
        <h1 className="page_title">Servicios</h1>

        <div className={styles.texts}>
          <p>
            Tratamiento de los trabajos a realizar: Se presupuesta el trabajo
            solicitado por el cliente, luego de su aprobación se coordina un
            turno. Se intenta resolver el trabajo en el día. Luego se entregan
            las fotos e informe demostrando la resolución de lo pactado.
          </p>

          <p>
            A la hora de realizar el diagnóstico de una falla: se utilizan
            diferentes instrumentos para entender el estado de la moto. Estado
            del motor, sistema de encendido y sistema de inyección/carburación.
            Los tres pilares para que funcione correctamente, ya que si tenemos
            compresión, chispa y combustible en su medida correcta; no puede
            haber mal funcionamiento.
          </p>

          <p>
            A la hora de realizar un servicio: se consulta la tabla de
            mantenimiento de la moto en cuestión, y en base a eso se programa el
            trabajo a efectuar. Ya que no hay nadie más capacitado que el
            fabricante para determinar cada cuántos kilómetros y tiempo realizar
            el servicio.
          </p>
        </div>

        <div className={styles.cards}>
          <div className={styles.card}>
            <TbZoomQuestion />
            <h2>Diagnóstico</h2>

            <p>
              Realización de mediciones, interpretarlas y determinar la causa de
              la falla para luego tomar acción.
            </p>
          </div>
          <div className={styles.card}>
            <GiAutoRepair />
            <h2>Reparaciones</h2>

            <p>
              Una vez entendida la causa del problema, se procede a reparar o
              cambiar los componentes necesarios.
            </p>
          </div>
          <div className={styles.card}>
            <BsTools />
            <h2>Mantenimiento</h2>

            <p>
              Se realizan en base a lo que solicita en manual de usuario de la
              moto y si corresponde se realizan controles más al detalle.
            </p>
          </div>
          <div className={styles.card}>
            <MdOutlineSell />
            <h2>Venta</h2>

            <p>
              Vendemos motos que hayan pasado por el taller y hayamos
              corroborado el correcto estado general.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
