import React, { useState } from "react";
import styles from "./ContactUs.module.scss";
import SectionGradient from "../common/SectionGradient/SectionGradient";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaClock, FaInstagram, FaPhone, FaWhatsapp } from "react-icons/fa6";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const ContactUs = () => {
  const initialContact = {
    name: "",
    email: "",
    phone: "",
    body: "",
  };

  const [contact, setContact] = useState(initialContact);
  const [isSending, setIsSending] = useState(false);

  const handleChange = (name, value) => {
    setContact((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const fireError = () => {
    Swal.fire({
      title: "¡Error al enviar!",
      text: "Por favor intente nuevamente en unos minutos.",
      icon: "error",
    });
  };

  const sendContact = async (event) => {
    event.preventDefault();
    setIsSending(true);

    try {
      const response = await fetch(
        "https://webapplication120240725104935.azurewebsites.net/api/MailSender/Update",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contact),
        }
      );

      if (response.ok) {
        Swal.fire({
          title: "¡Mensaje enviado correctamente!",
          text: "Responderemos su consulta a la brevedad.",
          icon: "success",
        });
        setContact(initialContact);
      } else {
        fireError();
      }
    } catch (err) {
      fireError();
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className={styles.contact}>
      <SectionGradient />

      <div className={`wrapper ${styles.contact_content}`}>
        <div>
          <h1 className="page_title">Contacto</h1>
          <p className={styles.subtitle}>
            Envianos tus datos y tu consulta y nos estaremos comunicando con vos
            a la brevedad. O si preferís podés enviarnos un WhatsApp{" "}
            <a
              href="https://api.whatsapp.com/send?phone=541156372706"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <FaWhatsapp /> haciendo click acá
            </a>
            .
          </p>
          <form
            action="#"
            className={styles.form}
            onSubmit={(e) => sendContact(e)}
          >
            <div className={styles.form_block}>
              <label htmlFor="">Nombre</label>
              <input
                required
                name="name"
                type="text"
                value={contact.name}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </div>
            <div className={styles.form_block}>
              <label htmlFor="">Teléfono</label>
              <input
                name="phone"
                type="phone"
                value={contact.phone}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </div>
            <div className={styles.form_block}>
              <label htmlFor="">Correo</label>
              <input
                required
                name="email"
                type="email"
                value={contact.email}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </div>
            <div className={styles.form_block}>
              <label htmlFor="">Mensaje</label>
              <textarea
                required
                name="body"
                value={contact.body}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              ></textarea>
            </div>
            <br />
            <button
              className={`${styles.submit} button`}
              type="submit"
              disabled={isSending}
            >
              Enviar
            </button>
          </form>
        </div>

        <div>
          <iframe
            className={styles.map}
            title="Mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3286.049075908462!2d-58.47399879999999!3d-34.5523128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb7db16deb68f%3A0xecd0ad1f86b0cbc!2sMotoasesor!5e0!3m2!1ses-419!2sar!4v1723508391677!5m2!1ses-419!2sar"
            width="600"
            height="300"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

          <div className={styles.data}>
            <ul className={styles.list}>
              <li>
                <FaMapMarkerAlt />
                <span>Crisólogo Larralde 3060, Nuñez, Buenos Aires</span>
                <br />
                <span className={styles.list_sp}>
                  <b>(solo con turno previo)</b>
                </span>
              </li>
              <li>
                <FaPhone />
                <span>11 5637 2706</span>
              </li>
              <li>
                <FaClock />
                <span>Lunes a viernes de 8:30 a 17:30 hs</span>
              </li>
            </ul>

            <div className={styles.socials}>
              <a
                href="https://www.instagram.com/motoasesor"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=541156372706"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
