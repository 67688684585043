import React, { useState } from "react";
import { FaTools } from "react-icons/fa";
import { IoStorefront } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { NavLink, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import routes from "../../../constants/routes";
import useStore from "../../../store/store";
import { getInitials } from "../../../utils";
import styles from "./Sidebar.module.scss";

const useNavLinkActive = (basePath) => {
  const location = useLocation();

  const isActive = (match, location) => {
    if (!match) {
      return false;
    }
    return location.pathname.startsWith(basePath);
  };

  return isActive;
};

const Sidebar = () => {
  const user = useStore((store) => store.user);
  const [loggingOut, setLoggingOut] = useState();

  const open = useStore((store) => store.sidebarOpen);
  const setOpen = useStore((store) => store.setSidebarOpen);

  const handleLogout = async (e) => {
    e.preventDefault();
    setLoggingOut(true);

    try {
      const response = await fetch(
        "https://webapplication120240725104935.azurewebsites.net/api/Auth/logout",
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (response.ok) {
        window.location.reload();
      }
    } catch (error) {
      setLoggingOut(false);
      Swal.fire({
        icon: "error",
        title: "Error del servidor",
        text: "Por favor intente de nuevo",
      });
    }
  };

  const isActiveProducts = useNavLinkActive(routes.adminProducts.list.path);
  const isActiveJobs = useNavLinkActive(routes.adminJobs.list.path);

  return (
    <>
      <div className='admin_hamburguer_wrap'>
        <button
          className={`admin_hamburguer ${open ? "open" : ""}`}
          onClick={() => setOpen(!open)}
        >
          <RxHamburgerMenu />
        </button>
      </div>
      <div className={`${styles.sidebar} ${open ? styles.open : ""}`}>
        <div className={styles.user}>
          <div className={styles.avatar}>{getInitials(user.full_name)}</div>
          <p className={styles.user_name}>Bienvenido, {user.full_name}</p>

          <button onClick={handleLogout} className='link' disabled={loggingOut}>
            Cerrar sesión
          </button>
        </div>
        <div className={styles.links}>
          {/* <NavLink className={styles.link} to={routes.adminUsers.path}>
          <FaUsers />
          <span>Usuarios</span>
        </NavLink> */}
          <NavLink
            className={({ isActive: active }) =>
              `${styles.link} ${active ? styles.active : ""}`
            }
            to={routes.adminProducts.list.path}
            isActive={isActiveProducts}
            onClick={() => setOpen(false)}
          >
            <IoStorefront />
            <span>Productos</span>
          </NavLink>
          <NavLink
            className={({ isActive: active }) =>
              `${styles.link} ${active ? styles.active : ""}`
            }
            to={routes.adminJobs.list.path}
            isActive={isActiveJobs}
            onClick={() => setOpen(false)}
          >
            <FaTools />
            <span>Trabajos</span>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
