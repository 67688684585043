import React, { useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import { RiMotorbikeFill } from "react-icons/ri";
import { Button } from "@mui/material";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import QUERIES from "../../../../constants/queries";
import styles from "./Jobs.module.scss";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { formatByThousands } from "../../../../utils";
import { Link } from "react-router-dom";
import routes from "../../../../constants/routes";
import { IoStorefront } from "react-icons/io5";
import { TbTrash } from "react-icons/tb";
import Swal from "sweetalert2";

const Jobs = () => {
  const [jobs, setJobs] = useState([]);

  const fetchJobs = async () => {
    const response = await fetch(
      "https://webapplication120240725104935.azurewebsites.net/api/Job/GetAll"
    );
    if (!response.ok) {
      throw new Error("Error fetching");
    }
    return response.json();
  };

  const { isFetching, error } = useQuery(QUERIES.FETCH_JOBS_ADMIN, fetchJobs, {
    onSuccess: (data) => {
      setJobs(data);
    },
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();

  const deleteJob = async (id) => {
    await fetch(
      `https://webapplication120240725104935.azurewebsites.net/api/Job/Delete?id=${id}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const deleteMutation = useMutation(deleteJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.FETCH_JOBS_ADMIN);
    },
    onError: () => {
      Swal.fire({
        title: "Error eliminando trabajo",
        text: "Por favor intentalo nuevamente en unos minutos",
        icon: "error",
      });
    },
  });

  const handleDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    Swal.fire({
      title: "¿Seguro que desea eliminar el trabajo?",
      text: "Esta acción no puede deshacerse",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#666",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) deleteMutation.mutate(id);
    });
  };

  return (
    <div className="admin">
      <Sidebar />
      <div className="admin_content">
        <div className="admin_header">
          <h1>
            <IoStorefront />
            Trabajos
          </h1>

          <div>
            <Link to={routes.adminJobs.new.path}>
              <Button variant="contained">Cargar trabajos</Button>
            </Link>
          </div>
        </div>

        <div className="admin_data">
          {isFetching ? (
            <div className="admin_loading">
              <AiOutlineLoading3Quarters />
            </div>
          ) : (
            <div className={styles.jobs}>
              {jobs.map((job) => (
                <Link
                  to={`${routes.adminJobs.list.path}/${job.id}`}
                  className={styles.job}
                  key={job.id}
                >
                  <button
                    className={styles.deleteButton}
                    onClick={(e) => handleDelete(e, job.id)}
                  >
                    <TbTrash />
                  </button>
                  <img
                    className={styles.img}
                    src={job.urlPhotos?.[0]}
                    alt={job.name}
                    height={200}
                  />
                  <div className={styles.data}>
                    <p className={styles.title}>{job.title}</p>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Jobs;
