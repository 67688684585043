import React from "react";
import { Link } from "react-router-dom";
import { ReactTyped } from "react-typed";
import HeroImg from "../../assets/hero.jpg";
import routes from "../../constants/routes";
import styles from "./Home.module.scss";
import HomeProducts from "./HomeProducts/HomeProducts";
import HomeUs from "./HomeUs/HomeUs";

const Home = (props) => {
  return (
    <>
      <div
        className={styles.hero}
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className={`wrapper ${styles.wrap}`}>
          <h2 className={styles.upperTitle}>
            Motoasesor es confianza, garantía y eficiencia.
          </h2>
          <h1 className={styles.title}>
            <div className={styles.dynamic_text_pc}>
              <span className={styles.title_fixed}>¿Querés </span>
              <ReactTyped
                loop
                typeSpeed={50}
                strings={[
                  "reparar tu moto o hacerle service",
                  "comprar una moto garantizada",
                  "unirte al grupo de WhatsApp para aprender mecánica",
                ]}
                className={styles.heroText}
              ></ReactTyped>{" "}
              <span className={styles.title_fixed}>?</span>
            </div>
            <div className={styles.dynamic_text_mobile}>
              <span className={styles.title_fixed}>¿Querés </span>
              <ReactTyped
                loop
                typeSpeed={50}
                strings={[
                  "reparar tu moto o hacerle service?",
                  "comprar una moto garantizada?",
                  "unirte al grupo de WhatsApp para aprender mecánica?",
                ]}
                className={styles.heroText}
              ></ReactTyped>{" "}
            </div>
          </h1>

          <p className={`${styles.subtitle} ${styles.first}`}>
            Realizamos diagnóstico avanzado, reparación y mantenimiento de
            motos.
          </p>
          <p className={`${styles.subtitle}`}>Vendemos motos garantizadas.</p>
          <p className={`${styles.subtitle}`}>
            Unite al{" "}
            <a
              href="https://api.whatsapp.com/send?phone=541156372706"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              grupo de WhatsApp exclusivo
            </a>{" "}
            si querés aprender mecánica.
          </p>

          <div className={styles.heroLinkWrap}>
            <Link className={styles.heroLink} to={routes.contact.path}>
              Contactanos ahora
            </Link>
          </div>
        </div>
      </div>
      <HomeUs />
      <HomeProducts />
    </>
  );
};

Home.propTypes = {};

export default Home;
