const QUERIES = {
  FETCH_PRODUCTS_HOME: "FETCH_PRODUCTS_HOME",
  FETCH_PRODUCTS: "FETCH_PRODUCTS",
  FETCH_PRODUCT: "FETCH_PRODUCT",
  FETCH_JOBS: "FETCH_JOBS",
  FETCH_PRODUCTS_ADMIN: "FETCH_PRODUCTS_ADMIN",
  FETCH_PRODUCT_ADMIN: "FETCH_PRODUCT_ADMIN",
  FETCH_JOBS_ADMIN: "FETCH_JOBS_ADMIN",
  FETCH_JOB_ADMIN: "FETCH_JOB_ADMIN"
};

export default QUERIES;
