import React, { useEffect, useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import "react-quill/dist/quill.snow.css";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import QUERIES from "../../../../constants/queries";
import styles from "./JobForm.module.scss";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import routes from "../../../../constants/routes";
import { Controller, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";
import { IoStorefront } from "react-icons/io5";
import Swal from "sweetalert2";
import Pics from "../../common/Pics";
import Vids from "../../common/Vids";

const photoErr = "Al menos tiene que haber una imagen.";

const dropzoneStyles = {
  border: "2px dashed #ccc",
  padding: "20px",
  marginBottom: "20px",
  marginTop: "4px",
};

const JobForm = () => {
  const [photoError, setPhotoError] = useState("");
  const [editModePhotos, setEditModePhotos] = useState([]);
  const [editModeVideos, setEditModeVideos] = useState([]);

  const navigate = useNavigate();

  const { id } = useParams();

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      title: "",
      description: "",
      photos: [],
      videos: [],
      isBike: true,
    },
  });

  /* If there's an id, call the query */
  const fetchJob = async () => {
    const response = await fetch(
      `https://webapplication120240725104935.azurewebsites.net/api/Job/Get?id=${id}`
    );

    if (!response.ok) {
      const error = new Error("An error occurred while fetching the data.");
      error.status = response.status;
      throw error;
    }

    return response.json();
  };

  const { isFetching, error } = useQuery([QUERIES.FETCH_JOB_ADMIN], fetchJob, {
    onSuccess: (data) => {
      setValue("title", data.title);
      setValue("description", data.description);

      setEditModePhotos(data.urlPhotos);
      setEditModeVideos(data.urlVideos);
    },
    onError: (error) => {
      navigate(routes.adminJobs.list.path);
    },
    refetchOnWindowFocus: false,
    enabled: typeof id === "string" && !!id,
    retry: (failureCount, error) => {
      if (error.status === 404) return false;
      return failureCount < 1;
    },
  });

  console.log({ editModeVideos });

  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);

  const uploadFiles = async ({ id, files }) => {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("files", file);
    });

    const response = await fetch(
      `https://webapplication120240725104935.azurewebsites.net/api/Job/UploadFile?jobId=${id}`,
      {
        method: "POST",
        body: formData,
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    return true;
  };

  const uploadFilesMutation = useMutation(uploadFiles, {
    onSuccess: () => {
      Swal.fire({
        title: `¡Trabajo ${!!id ? "editado" : "creado"}!`,
        icon: "success",
      }).then(() => {
        navigate(routes.adminJobs.list.path);
      });
    },
    onError: () => {
      Swal.fire({
        title: "Error subiendo los archivos",
        text: "Por favor, edite el trabajo para intentar subir los archivos nuevamente",
        icon: "error",
      }).then(() => {
        navigate(routes.adminJobs.list.path);
      });
    },
  });

  const createEditJob = async (job) => {
    console.log({ job });
    const response = await fetch(
      `https://webapplication120240725104935.azurewebsites.net/api/job/${
        !!id ? "Update" : "Create"
      }`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(job),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  };

  const createMutation = useMutation(createEditJob, {
    onSuccess: (data) => {
      const id = data.id;
      uploadFilesMutation.mutate({ id, files: [...photos, ...videos] });
    },
    onError: () => {
      Swal.fire({
        title: "Error cargando el trabajo",
        text: "Por favor intentalo nuevamente en unos minutos",
        icon: "error",
      });
    },
  });

  const editMutation = useMutation(createEditJob, {
    onSuccess: (data) => {
      const id = data.id;
      if (photos.length > 0 || videos.length > 0) {
        uploadFilesMutation.mutate({ id, files: [...photos, ...videos] });
      } else {
        Swal.fire({
          title: "!Trabajo editado!",
          icon: "success",
        }).then(() => {
          navigate(routes.adminJobs.list.path);
        });
      }
    },
    onError: () => {
      Swal.fire({
        title: "Error editando el trabajo",
        text: "Por favor intentalo nuevamente en unos minutos",
        icon: "error",
      });
    },
  });

  const onSubmit = (data) => {
    if (
      (photos.length === 0 && !id) ||
      (!!id && photos.length === 0 && editModePhotos.length === 0)
    ) {
      setPhotoError(photoErr);
      return;
    }

    const body = {
      title: data.title,
      description: data.description,
      photos: data.photos,
      videos: data.videos,
    };

    if (!!id) {
      body.id = id;
      body.urlPhotos = editModePhotos;
      body.urlVideos = editModeVideos;

      editMutation.mutate(body);
    } else {
      body.id = "";
      body.urlPhotos = [];
      body.urlVideos = [];

      createMutation.mutate(body);
    }
  };

  const { getRootProps: getRootPropsVid, getInputProps: getInputPropsVid } =
    useDropzone({
      accept: {
        "video/mp4": [".mp4"],
      },

      maxSize: 400 * 1024 * 1024,
      maxFiles: 10,
      onDrop: (acceptedFiles) => {
        setVideos((prevVideos) => [...prevVideos, ...acceptedFiles]);
        setValue("videos", [...videos, ...acceptedFiles]);
      },
    });

  const { getRootProps: getRootPropsImg, getInputProps: getInputPropsImg } =
    useDropzone({
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
      },
      maxSize: 6 * 1024 * 1024, // 6MB
      maxFiles: 10,
      onDrop: (acceptedFiles) => {
        const newPhotos = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
        setValue("photos", [...photos, ...newPhotos]);
        setPhotoError("");
      },
    });

  // Clean up object URLs to avoid memory leaks
  useEffect(() => {
    return () => photos.forEach((photo) => URL.revokeObjectURL(photo.preview));
  }, [photos]);

  const removeExistentFile = async ({ url }) => {
    await fetch(
      `https://webapplication120240725104935.azurewebsites.net/api/job/DeleteFile?fileUrl=${url}&jobId=${id}`,
      {
        method: "POST",
        credentials: "include",
      }
    );
  };

  const queryClient = useQueryClient();

  const deletePhotoMutation = useMutation(removeExistentFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.FETCH_JOB_ADMIN);
    },
    onError: () => {
      Swal.fire({
        title: "Error al eliminar la foto",
        text: "Por favor intente nuevamente",
        icon: "error",
      });
    },
  });

  const deleteVideoMutation = useMutation(removeExistentFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.FETCH_JOB_ADMIN);
    },
    onError: () => {
      Swal.fire({
        title: "Error al eliminar el video",
        text: "Por favor intente nuevamente",
        icon: "error",
      });
    },
  });

  const removePhoto = (index) => {
    setPhotos((prevPhotos) => {
      const newPhotos = [...prevPhotos];
      URL.revokeObjectURL(newPhotos[index].preview);
      newPhotos.splice(index, 1);
      setValue("photos", newPhotos);

      if (
        (photos.length === 0 && !id) ||
        (!!id && photos.length === 0 && editModePhotos.length === 0)
      ) {
        setPhotoError(photoErr);
      }

      return newPhotos;
    });
  };

  const removeVideo = (index) => {
    setVideos((prevVideos) => {
      const newVideos = [...prevVideos];
      newVideos.splice(index, 1);
      setValue("videos", newVideos);
      return newVideos;
    });
  };

  console.log({ videos });

  const isLoading =
    createMutation.isLoading ||
    uploadFilesMutation.isLoading ||
    deletePhotoMutation.isLoading ||
    editMutation.isLoading ||
    isFetching;

  return (
    <div className="admin">
      <Sidebar />
      <div className="admin_content">
        <div className="admin_header">
          <h1>
            <IoStorefront />
            {id ? "Editar trabajo" : "Cargar trabajo"}
          </h1>

          <div>
            <Link to={routes.adminJobs.list.path}>
              <Button variant="contained">Volver atrás</Button>
            </Link>
          </div>
        </div>

        <div className="admin_data">
          {isLoading && (
            <div className="admin_loading_overlay">
              <div className="admin_loading_overlay_loader">
                <AiOutlineLoading3Quarters />
              </div>
            </div>
          )}
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="title"
              control={control}
              rules={{ required: true, minLength: 4, maxLength: 255 }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Título"
                  fullWidth
                  margin="normal"
                  variant="filled"
                  error={!!error}
                  helperText={
                    error
                      ? "El título no puede estar vacío (4-255 caractéres)"
                      : ""
                  }
                />
              )}
            />
            <InputLabel>Descripción</InputLabel>
            <Controller
              name="description"
              control={control}
              rules={{ required: true, maxLength: 50000 }}
              render={({ field }) => (
                <ReactQuill
                  {...field}
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "underline"],
                      [{ size: ["small", false, "large", "huge"] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ align: [] }],
                    ],
                  }}
                  style={{ height: "200px", marginBottom: "50px" }}
                />
              )}
            />

            <InputLabel>Imágenes</InputLabel>
            <Box {...getRootPropsImg()} style={dropzoneStyles}>
              <input {...getInputPropsImg()} />
              <Typography>
                Haz click aquí o arrastra para subir las fotos
              </Typography>
              <Typography variant="caption">
                Sólo se aceptan archivos *.jpg, *.jpeg o *.png de 6MB o menos
              </Typography>
            </Box>
            {photoError && (
              <Typography color="error" variant="body2">
                {photoError}
              </Typography>
            )}

            {/* New photos */}
            <Pics photos={photos} removePhoto={removePhoto} />

            {/* Existent photos */}
            <Pics
              photos={editModePhotos}
              removePhoto={deletePhotoMutation}
              existent
            />

            <InputLabel>Videos</InputLabel>
            <Box {...getRootPropsVid()} style={dropzoneStyles}>
              <input {...getInputPropsVid()} />
              <Typography>
                Haz click aquí o arrastra para subir los videos
              </Typography>
              <Typography variant="caption">
                Sólo se aceptan archivos *.mp4, de 400MB o menos
              </Typography>
            </Box>

            {/* New videos */}
            <Vids videos={videos} removeVideo={removeVideo} />

            {/* Existent videos */}
            <Vids
              videos={editModeVideos}
              removeVideo={deleteVideoMutation}
              existent
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
              disabled={isLoading}
            >
              {!!id ? "Guardar cambios" : "Cargar trabajo"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JobForm;
