import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FcVideoFile } from "react-icons/fc";

const Vids = ({ videos, removeVideo, existent = false }) => {
  if (videos.length === 0) return null;

  return (
    <Box mt={2}>
      <Typography variant="h6" gutterBottom>
        {existent ? "Videos actuales" : "Videos nuevos seleccionados"} (
        {videos.length})
      </Typography>
      <Grid container spacing={2}>
        {videos.map((video, index) => (
          <Grid
            item
            xs={6}
            sm={4}
            md={existent ? 5 : 3}
            key={existent ? video : video.name}
          >
            <Box
              position="relative"
              sx={{
                "&:hover .remove-btn": { display: "block" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "12px",
                overflow: "hidden",
              }}
            >
              {existent ? (
                <video
                  controls={false}
                  autoPlay
                  loop
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "250px",
                  }}
                  muted
                >
                  <source
                    src={video}
                    type={video.endsWith(".mp4") ? "video/mp4" : ""}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <>
                  <FcVideoFile style={{ fontSize: "32px" }} />
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{
                      mt: 2,
                      width: "100%",
                      wordWrap: "break-word",
                      padding: "0 4px",
                    }}
                    title={video.name}
                  >
                    {video.name}
                  </Typography>
                </>
              )}
              <Button
                className="remove-btn"
                variant="contained"
                color="error"
                size="small"
                onClick={() => {
                  if (existent) {
                    removeVideo.mutate({ url: video });
                  } else {
                    removeVideo(index);
                  }
                }}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "none",
                  minWidth: "30px",
                  width: "30px",
                  height: "30px",
                  padding: 0,
                }}
              >
                X
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Vids;
